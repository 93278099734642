export const createOption = (data, settings, dataset, appConfig) => {
  const {
    labelColumn, valueColumn, latitudeColumn, longitudeColumn,
  } = settings;
  const d = data.filter((item) => !isNaN(item[valueColumn]) && item[latitudeColumn] && item[longitudeColumn]).map((item) => {
    const result = {
      name: item[labelColumn],
      value: [item[longitudeColumn], item[latitudeColumn], item[valueColumn]],
    };
    return result;
  });
  const max = Math.max(...d.map((item) => item.value[2]));
  const min = Math.min(...d.map((item) => item.value[2]));
  const config = appConfig.datasets.find((item) => item.table_name === dataset.table_name);

  //   console.log('max', max);
  const add = min < 0 ? (Math.abs(min) + 5) : 0;

  const top5 = d
    .sort((a, b) => parseFloat(b[2]) - parseFloat(a[2]))
    .slice(0, 6);
  //   console.log('top5', top5);

  const option = {
    title: {
      text: config.name,
      subtext: '',
      left: 'right',
    },
    tooltip: {
      trigger: 'item',
      showDelay: 0,
      transitionDuration: 0.2,
    },
    toolbox: {
      show: true,
      // orient: 'vertical',
      left: 'left',
      top: 'top',
      feature: {
        dataView: { readOnly: false },
        restore: {},
        saveAsImage: {},
      },
    },
    grid: {
      top: '-10%',
      bottom: '12%',
      right: '2%',
    },
    geo: {
      map: 'uk',
      show: true,
      silent: true,
      roam: false,
      nameProperty: 'NAME_2',
      emphasis: {
        label: {
          show: false,
          areaColor: '#000',
        },
      },
      itemStyle: {
        areaColor: '#f3f3f3',
        borderWidth: 0.2,
        borderColor: '#818EB7',
      },
    //   left: '6%',
    //   top: 40,
    //   bottom: '10%',
    //   right: '14%',
    },
    series: [
      {
        name: 'Location',
        type: 'scatter',
        coordinateSystem: 'geo',
        data: d,
        symbolSize(val) {
          return ((val[2] + add) / (max + add)) * 20;
        },
        encode: {
          value: 2,
        },
        label: {
          formatter: '{b}',
          position: 'right',
          show: false,
        },
        emphasis: {
          label: {
            show: false,
          },
        },
      },
      {
        name: 'Top 5',
        type: 'effectScatter',
        coordinateSystem: 'geo',
        data: d
          .sort((a, b) => parseFloat(b.value[2]) - parseFloat(a.value[2]))
          .slice(0, 5),
        symbolSize(val) {
          return ((val[2] + add) / (max + add)) * 30;
        },
        encode: {
          value: 2,
        },
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke',
        },
        label: {
          formatter: '{b}',
          position: 'right',
          show: true,
        },
        itemStyle: {
          shadowBlur: 10,
          shadowColor: '#333',
        },
        emphasis: {
          scale: true,
        },
        zlevel: 1,
      },
    ],
  };
  return option;
};
