<template>
  <div
    ref="echart"
    v-resize="onResize"
    style="width: 1200px; height: inherit"
  />
</template>
<script>
import * as echarts from 'echarts/dist/echarts.js';
import ukJSON from './data/uk-counties.geo.json';
// import worldJSON from './data/world.json';
import ApiService from '../../../services/api.service';
import { createOption } from './lib/transformer';

import { createMapQueryParams } from '../../../lib/query-creator';

echarts.registerMap('uk', ukJSON);
// echarts.registerMap('world', worldJSON);

export default {
  components: {

  },
  props: ['dataset'],
  data() {
    return {
      chart: null,
    };
  },
  computed: {

  },
  mounted() {
    this.init();
    this.loadData();
  },
  methods: {
    init() {
      this.chart = echarts.init(this.$refs.echart);
    },
    draw(option) {
      // this.chart = echarts.init(this.$refs.echart);
      this.chart.setOption(option);
    },
    rendered() {

    },
    onResize() {
      if (this.chart) {
        this.chart.resize();
      }
    },
    async loadData() {
      this.$store.commit('mSetLoading', true);
      if (this.dataset && this.dataset.table_name) {
        const appConfig = this.$store.state.appConfig;

        // console.log('activeDataset.uiKey', this.dataset.uiKey);

        // console.log('this.dataset', this.dataset);
        const result = createMapQueryParams(this.dataset);
        // const sql = createSeriesQuery(this.dataset);
        const response = await ApiService.post('/raw_sql/', { q: result.sql });
        const params = {
          latitudeColumn: 'Lat best',
          longitudeColumn: 'Long best',
          valueColumn: 'value',
          labelColumn: 'Location',
        };
        const data = response.data;
        const option = createOption(data, params, this.dataset, appConfig);
        console.log('option', option);
        this.draw(option);
      }
      this.$store.commit('mSetLoading', false);
    },
  },
};

</script>
